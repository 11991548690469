<template>
  <div
    class="wrapper"
    :style="{ width: drawerWidth }"
    @mouseover="isOpen = true"
    @mouseleave="isOpen = false"
  >
    <div v-for="item in menuItems" :key="item.label">
      <MenuItem
        v-if="item.access"
        :iconName="item.iconName"
        :label="item.label"
        :to="item.to"
        :href="item.href"
        :target="item.target"
      />
    </div>
  </div>
</template>

<script>
import MenuItem from "@/views/layouts/default/DrawerComponents/MenuItem"
import { mapGetters } from "vuex"

const OPEN_DRAWER_WIDTH = 275
const CLOSED_DRAWER_WIDTH = 92
const DRAWER_HORIZONTAL_PADDING = 46

export default {
  name: "Drawer",
  components: {
    MenuItem,
  },
  data() {
    return {
      isOpen: false,
      OPEN_DRAWER_WIDTH,
      CLOSED_DRAWER_WIDTH,
    }
  },
  computed: {
    ...mapGetters("generic", ["agentDetail"]),
    isAccounting() {
      return this.agentDetail && this.agentDetail.accounting
    },
    menuItems() {
      return [
        {
          iconName: "Template",
          label: "Dashboard",
          to: "/dashboard",
          access: true,
        },
        {
          iconName: "User",
          label: "Customers",
          to: "/customers/list",
          access: true,
        },
        {
          iconName: "GlobeAlt",
          label: "Locations",
          to: "/locations/list",
          access: true,
        },
        {
          iconName: "Truck",
          label: "Carriers",
          to: { name: "Carriers" },
          access: true,
        },
        {
          label: "Loads",
          iconName: "Loads",
          to: { name: "Loads" },
          access: true,
        },
        {
          label: "Quotes",
          iconName: "Annotation",
          to: "/shipments/quotes",
          access: true,
        },
        {
          iconName: "Cube",
          label: "LTL",
          href: "https://tms.reconlogistics.com",
          target: "_blank",
          access: true,
        },
        {
          iconName: "Import",
          label: "CSV Import",
          to: "/csv-import",
          access: true,
        },
        {
          label: "Receivables",
          iconName: "Cash",
          to: "/accounting/receivables",
          access: this.isAccounting,
        },
        {
          label: "Payables",
          iconName: "Cash",
          to: "/accounting/payables",
          access: this.isAccounting,
        },
        {
          label: "Load Payments",
          iconName: "Cash",
          to: "/accounting/load-payment",
          access: this.isAccounting,
        },
        {
          label: "Approved Payments",
          iconName: "Cash",
          to: "/accounting/approved-payment",
          access: this.isAccounting,
        },
        {
          label: "Factoring Companies",
          iconName: "Cash",
          to: "/factoring",
          access: this.isAccounting,
        },
        {
          label: "Knowledge Base",
          iconName: "Book",
          href: "https://www.notion.so/exo-freight",
          target: "_blank",
          access: true,
        },
        {
          label: "Quoting Tool",
          iconName: "Dollar",
          href: "https://tms.v2.exofreight.com/",
          target: "_blank",
          access: true,
        },
      ]
    },
    drawerWidth() {
      return `${this.isOpen ? OPEN_DRAWER_WIDTH : CLOSED_DRAWER_WIDTH}px`
    },
    itemWidth() {
      return `${
        this.isOpen
          ? OPEN_DRAWER_WIDTH - DRAWER_HORIZONTAL_PADDING
          : CLOSED_DRAWER_WIDTH - DRAWER_HORIZONTAL_PADDING
      }px`
    },
  },
}
</script>

<style scoped>
.wrapper {
  position: absolute;
  top: var(--appbar-height);
  left: 0;
  height: calc(100vh - var(--appbar-height));
  min-height: calc(100% - var(--appbar-height));
  padding: 16px 24px;
  transition: width 100ms linear;
  background-color: var(--white);
  border-right: 1px solid var(--neutral-2);
  z-index: 10;
}
</style>
