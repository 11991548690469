<template>
  <v-app>
    <AppBar />

    <Drawer />

    <Content />

    <v-alert
      :value="snackbar.status"
      :type="snackbar.type"
      transition="slide-x-transition"
      prominent
      style="z-index: 9999; right: 0; bottom: 20px; position: fixed"
    >
      <b style="font-size: 14px">{{ snackbar.text }}</b>
      <v-btn icon @click="closeAlert()">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-alert>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex"
import AppBar from "@/views/layouts/default/AppBar"
import Drawer from "@/views/layouts/default/Drawer"
import Content from "@/views/layouts/default/Content"

export default {
  name: "DefaultLayoutIndex",
  components: {
    AppBar,
    Drawer,
    Content,
  },
  computed: {
    ...mapGetters("main", {
      snackbar: "snackbarData",
    }),
  },
  mounted: function () {
    this.hideAlert()
  },
  methods: {
    hideAlert() {
      window.setInterval(() => {
        if (this.snackbar.status) {
          const snackbar = {
            status: false,
            text: null,
            type: null,
          }
          this.$store.commit("main/SET_SNACKBAR", snackbar)
        }
      }, 10000)
    },
    closeAlert() {
      const snackbar = {
        status: false,
        text: null,
        type: null,
      }
      this.$store.commit("main/SET_SNACKBAR", snackbar)
    },
  },
}
</script>
