<template>
  <div id="create-customer">
    <v-dialog v-model="customerDialog" persistent width="600">
      <v-card>
        <v-card-title style="background-color: #1e88e5">
          <v-row class="align-center">
            <v-col cols="12">
              <span class="text-h2 white--text">Create Customer</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col class="pb-0" cols="12">
                  <v-text-field
                    v-model="customer.name"
                    :rules="[rules.required, rules.name]"
                    class="pt-0"
                    color="info"
                    label="Name"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-account </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    v-model="customer.address"
                    :rules="[rules.required, rules.alphaNumeric]"
                    class="pt-0"
                    color="info"
                    label="Address"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-map-marker </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="customer.city"
                    :rules="[rules.required, rules.name]"
                    class="pt-0"
                    color="info"
                    label="City"
                  />
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="customer.state"
                    :rules="[rules.required, rules.name]"
                    class="pt-0"
                    color="info"
                    hint="Short state name (Ex. Texas - TX)"
                    label="State"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-select
                    v-model="customer.country"
                    :items="countries"
                    :rules="[rules.required]"
                    class="pt-0"
                    color="info"
                    item-text="name"
                    item-value="id"
                    label="Country"
                  />
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="customer.zipCode"
                    :rules="[rules.required, rules.postalCode]"
                    class="pt-0"
                    color="info"
                    label="Postal Code"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="customer.phone"
                    :rules="[rules.required, rules.phone]"
                    class="pt-0"
                    color="info"
                    label="Phone"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-phone </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="customer.email"
                    :rules="[rules.required, rules.email]"
                    class="pt-0"
                    color="info"
                    label="Email"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-email </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-icon class="float-left mt-2" color="#1E88E5" left>
                    mdi-home
                  </v-icon>
                  <agent-auto-complete agent-text="Account Owner" />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0 mt-n3" cols="6">
                  <account-manager-auto-complete
                    :default-manager="customer.accountManager"
                    color="#e1b753"
                    @blur="selectedAccountManager"
                    @event="selectedAccountManager"
                  />
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-select
                    v-model="customer.orgLevel"
                    :items="orgLevels"
                    class="pt-0"
                    color="info"
                    item-text="name"
                    item-value="id"
                    label="Organization Level"
                  />
                </v-col>
                <v-col
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="pt-0 pb-0"
                  cols="1"
                >
                  &nbsp;
                </v-col>
                <v-col v-if="customer.orgLevel == 2" class="pt-0 pb-0" cols="5">
                  <customer-auto-complete customer-text="Parent" />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer />
          <v-btn color="red" text @click="closeDialog()"> Close </v-btn>
          <v-btn color="info" text @click="submitCustomer()"> Create </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import AgentAutoComplete from "@/views/pages/components/AgentAutoComplete"
import CustomerAutoComplete from "@/views/pages/components/CustomerAutoComplete"
import validation from "@/utils/form-validation"
import common from "@/utils/common"
import AccountManagerAutoComplete from "@/views/pages/components/AccountManagerAutoComplete"
export default {
  name: "CreateCustomer",
  components: {
    AgentAutoComplete,
    CustomerAutoComplete,
    AccountManagerAutoComplete,
  },
  data() {
    return {
      valid: false,
      customer: {
        name: null,
        address: null,
        city: null,
        state: null,
        country: null,
        zipCode: null,
        phone: null,
        email: null,
        accountOwner: null,
        orgLevel: 1,
        parent: 0,
        accountManager: null,
      },
      orgLevels: [
        {
          id: 1,
          name: "Parent",
        },
        {
          id: 2,
          name: "Branch",
        },
      ],
      rules: validation.rules,
    }
  },
  computed: {
    ...mapGetters("create", ["customerDialog"]),
    ...mapGetters("generic", ["agentId", "customerId", "countries"]),
  },
  methods: {
    submitCustomer() {
      if (
        this.customer.orgLevel === 2 &&
        (this.customerId === null || this.customerId === 0)
      ) {
        const snackbar = {
          status: true,
          text: "Please select parent",
          type: common.data.snackbarType.error,
        }
        this.$store.commit("main/SET_SNACKBAR", snackbar)
      } else if (this.agentId === null || this.agentId === 0) {
        const snackbar = {
          status: true,
          text: "Please select account ownser",
          type: common.data.snackbarType.error,
        }
        this.$store.commit("main/SET_SNACKBAR", snackbar)
      } else {
        if (this.$refs.form.validate()) {
          const payload = {
            name: this.customer.name,
            account_owner: this.agentId,
            parent: this.customer.orgLevel === 2 ? this.customerId : null,
            organization_level: this.customer.orgLevel,
            website: this.customer.website,
            phone: this.customer.phone,
            address: this.customer.address,
            city: this.customer.city,
            state: this.customer.country == "USA" ? this.customer.state : "",
            us_zipcode:
              this.customer.country == "USA" ? this.customer.zipCode : "",
            ca_province:
              this.customer.country == "CAN" ? this.customer.state : "",
            ca_postal_code:
              this.customer.country == "CAN" ? this.customer.zipCode : "",
            country: this.customer.country,
            account_manager: this.customer.accountManager,
          }
          this.$store.dispatch("customer/CREATE_NEW_CUSTOMER", payload)
          this.$store.commit("create/setCustomerDialog", false)
        }
      }
    },
    closeDialog() {
      this.$store.commit("create/setCustomerDialog", false)
      this.$refs.form.reset()
    },
    selectedAccountManager(value) {
      this.customer.accountManager = value.manager.id
    },
  },
}
</script>
<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}
.v-textarea /deep/ .v-text-field__slot textarea {
  resize: none;
}
</style>
