<template>
  <div class="contentWrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Content",
}
</script>

<style scoped>
@import "../../../assets/constants.css";

.contentWrapper {
  padding-left: var(--closed-drawer-width);
}
</style>
