<template>
  <div id="create-carrier">
    <v-dialog v-model="carrierDialog" persistent width="500">
      <v-card>
        <v-card-title style="background-color: #1e88e5">
          <v-row class="align-center">
            <v-col cols="12">
              <span class="text-h2 white--text">Create Carrier</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="carrier.name"
                    label="Carrier Name"
                    color="info"
                    :rules="[rules.required, rules.alphaNumeric]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-office-building </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-icon color="#1E88E5" class="float-left mt-2 mr-2">
                    mdi-domain
                  </v-icon>
                  <customer-auto-complete />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="carrier.mcNumber"
                    label="MC Number"
                    color="info"
                    :rules="[rules.required, rules.intDigit]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-contacts </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="carrier.dotNumber"
                    label="DOT Number"
                    color="info"
                    :rules="[rules.required, rules.intDigit]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-contacts </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="carrier.phone"
                    label="Phone"
                    color="info"
                    :rules="[rules.required, rules.phone]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-phone </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="carrier.email"
                    label="Email"
                    color="info"
                    :rules="[rules.required, rules.email]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-email </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- Network Type -->
              <v-row v-if="isNetworkTypeSelectShown">
                <v-col cols="12">
                  <v-select
                    v-model="carrier.network"
                    label="Network Type"
                    :items="networkOptions"
                    :rules="[rules.required]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"
                        >mdi-account-network-outline</v-icon
                      >
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <!-- / Network Type -->
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="red" text @click="closeDialog()"> Close </v-btn>
          <v-btn color="info" text @click="submitCarrier()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import CustomerAutoComplete from "@/views/pages/components/CustomerAutoComplete"
import validation from "@/utils/form-validation"
import { NETWORK_TYPES } from "@/assets/constants"

export default {
  name: "CreateCarrier",
  components: {
    CustomerAutoComplete,
  },
  data() {
    return {
      valid: false,
      carrier: {
        name: null,
        mcNumber: null,
        dotNumber: null,
        phone: null,
        email: null,
        customerCompany: null,
        network: null,
      },
      rules: validation.rules,
      networkOptions: [
        {
          text: "Non Exo Network",
          value: NETWORK_TYPES.NON_EXO,
        },
        {
          text: "Private Network",
          value: NETWORK_TYPES.PRIVATE,
        },
      ],
    }
  },
  computed: {
    ...mapGetters("create", ["carrierDialog"]),
    ...mapGetters("generic", ["customerId"]),
    isNetworkTypeSelectShown() {
      return Boolean(this.customerId)
    },
  },

  methods: {
    closeDialog() {
      this.$store.commit("create/setCarrierDialog", false)
      this.$store.commit("generic/setCustomerId", null)
      this.$refs.form.reset()
    },
    async submitCarrier() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.carrier.customerCompany = this.customerId
      this.carrier.network = this.carrier.network || NETWORK_TYPES.DEFAULT
      await this.$store.dispatch("create/CREATE_CARRIER", this.carrier)
      this.$store.commit("generic/setCustomerId", null)
      this.$refs.form.reset()
    },
  },
}
</script>
