<template>
  <div class="wrapper">
    <Tooltip>
      <template v-slot:trigger>
        <Button theme="attention" size="small" icon-left="PlusCircle" />
      </template>
      <template v-slot:content>
        <div class="contentWrapper">
          <div class="column">
            <div class="menuTitle">General</div>
            <Button theme="dropdown" @click="openCapacityForm">
              Capacity
            </Button>
            <Button theme="dropdown" @click="openLoadForm">Load</Button>
            <Button theme="dropdown" @click="openLocationForm">
              Location
            </Button>
          </div>
          <div class="column">
            <div class="menuTitle">Account</div>
            <Button theme="dropdown" @click="openCustomerForm">
              Customer
            </Button>
            <Button theme="dropdown" @click="openCarrierForm">Carrier</Button>
            <Button theme="dropdown" @click="openFactoringForm">
              Factoring
            </Button>
          </div>
        </div>
      </template>
    </Tooltip>
  </div>
</template>

<script>
import Button from "@/views/components/Button"
import Tooltip from "@/views/components/Tooltip"

export default {
  name: "AddButton",
  components: {
    Button,
    Tooltip,
  },
  methods: {
    openCapacityForm() {
      this.$store.commit("create/setCapacityDialog", true)
    },
    openLoadForm() {
      this.$store.commit("create/setLoadDialog", true)
    },
    openLocationForm() {
      this.$store.commit("create/setLocationDialog", true)
    },
    openCustomerForm() {
      this.$store.commit("create/setCustomerDialog", true)
    },
    openCarrierForm() {
      this.$store.commit("create/setCarrierDialog", true)
    },
    openFactoringForm() {
      this.$store.commit("create/setFactoringDialog", true)
    },
  },
}
</script>

<style scoped>
.contentWrapper {
  display: flex;
}

.column {
  padding: 12px;
  border-right: 1px solid var(--neutral-2);
}

.column:last-child {
  border-right: none;
}

.menuTitle {
  padding: 12px;
  color: var(--link);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}
</style>
