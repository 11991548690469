<template>
  <component
    :is="to ? 'router-link' : 'a'"
    :to="to"
    :href="href"
    :target="target"
    class="DrawerMenuItem"
  >
    <icon
      :name="iconName"
      :color="colors['--dark-blue']"
      class="icon"
      size="20"
    />
    <span class="label">
      {{ label }}
    </span>
  </component>
</template>

<script>
import colors from "@/assets/colors.json"
import Icon from "@/views/components/Icon"

export default {
  name: "DrawerMenuItem",
  components: {
    Icon,
  },
  props: {
    iconName: {
      type: String,
    },
    label: {
      type: String,
    },
    to: {
      type: [Object, String],
    },
    href: {
      type: String,
    },
    target: {
      type: String,
    },
  },
  data() {
    return {
      colors,
    }
  },
}
</script>

<style scoped>
@import "../../../../assets/colors.css";

.DrawerMenuItem {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 8px;
  padding: 12px;
  width: 100%;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 8px;
  transition: all 200ms linear;
  overflow: hidden;
}

.DrawerMenuItem:hover {
  background-color: var(--neutral-2);
}

.DrawerMenuItem.router-link-active .label {
  color: var(--orange);
}

.DrawerMenuItem.router-link-active .icon {
  color: var(--orange);
  /*  stroke: var(--orange);*/
}

.icon {
  flex-shrink: 0;
  margin-right: 16px;
}

.label {
  color: var(--dark-blue);
}
</style>
