<template>
  <div class="wrapper">
    <Tooltip>
      <template v-slot:trigger>
        <span class="initials">
          {{ initials }}
        </span>
        <div class="chevronWrapper">
          <span class="chevron">
            <icon name="ChevronDown" :color="colors['--dark-blue']" size="8" />
          </span>
        </div>
      </template>
      <template v-slot:content>
        <div class="contentWrapper">
          <Button
            class="mb-2"
            theme="dropdown"
            @click="openCarrierSalesRepresentativeDashboard"
          >
            Carrier Sales Representative Dashboard
          </Button>
          <Button class="logoutButton" theme="dropdown" @click="logout"
            >Logout</Button
          >
        </div>
      </template>
    </Tooltip>
  </div>
</template>

<script>
import Button from "@/views/components/Button"
import Icon from "@/views/components/Icon"
import Tooltip from "@/views/components/Tooltip"
import colors from "@/assets/colors.json"
import common from "@/utils/common"
import { mapState } from "vuex"

export default {
  name: "Avatar",
  components: {
    Button,
    Icon,
    Tooltip,
  },
  data() {
    return {
      colors,
    }
  },
  computed: {
    ...mapState("generic", ["agentDetail"]),
    initials() {
      if (!this.agentDetail.user) {
        return ""
      }
      const firstName = this.agentDetail.user.firstName
      const lastName = this.agentDetail.user.lastName
      const initials =
        (firstName ? firstName[0] : "") + (lastName ? lastName[0] : "")
      return initials.toUpperCase()
    },
  },
  methods: {
    openCarrierSalesRepresentativeDashboard() {
      this.$router.push({ name: "Carrier Sales Representative Dashboard" })
    },
    logout() {
      common.methods.clearAllLocalStorage()
      this.$cookie.delete("token")
      this.$cookie.delete("django_redirect_url")
      this.$router.push("/pages/login")
    },
  },
}
</script>

<style scoped>
@import "../../../../assets/colors.css";

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--neutral-blue);
  border-radius: 50%;
  cursor: pointer;
}

.initials {
  color: var(--white);
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  user-select: none;
}

.chevronWrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  background-color: var(--yellow);
  border-radius: 50%;
}

.contentWrapper {
  padding: 16px;
}

.logoutButton {
  margin-left: auto;
}
</style>
