<template>
  <div id="create-factoring-company">
    <v-dialog v-model="factoringDialog" persistent width="500">
      <v-card>
        <v-card-title style="background-color: #1e88e5">
          <v-row class="align-center">
            <v-col cols="12">
              <span class="text-h2 white--text">Create Factoring Company</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="pt-0 pb-1 d">
                  <v-text-field
                    v-model="factoring.businessName"
                    label="Name"
                    color="info"
                    :rules="[rules.alphaNumeric, rules.required]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-contacts </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-0 pb-1">
                  <v-text-field
                    v-model="factoring.addressLine1"
                    label="Address"
                    color="info"
                    :rules="[rules.alphaNumericWithComma, rules.required]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-road </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-0 pb-1">
                  <v-text-field
                    v-model="factoring.city"
                    label="City"
                    color="info"
                    :rules="[rules.alphaNumeric, rules.required]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-road </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-0 pb-1">
                  <v-text-field
                    v-model="factoring.state"
                    label="State"
                    color="info"
                    :rules="[rules.alphaNumeric, rules.required]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-road </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-0 pb-1">
                  <v-text-field
                    v-model="factoring.postalCode"
                    label="Postal Code"
                    color="info"
                    :rules="[rules.postalCode, rules.required]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-road </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-0 pb-1">
                  <v-select
                    v-model="factoring.country"
                    :items="countries"
                    item-text="name"
                    item-value="id"
                    label="Country"
                    color="info"
                    :rules="[rules.required]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-map </v-icon>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6" class="pt-0 pb-1">
                  <v-text-field
                    v-model="factoring.phone"
                    label="Phone"
                    color="info"
                    :rules="[rules.phone, rules.required]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-phone </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-0 pb-1">
                  <v-text-field
                    v-model="factoring.email"
                    label="Email"
                    color="info"
                    :rules="[rules.email, rules.required]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-email </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-0 pb-1">
                  <v-text-field
                    v-model="factoring.contactName"
                    label="Contact Name"
                    color="info"
                    :rules="[rules.name]"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-account </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer />
          <v-btn color="red" text @click="closeDialog()"> Close </v-btn>
          <v-btn color="info" text @click="submitFactoringCompany()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import validation from "@/utils/form-validation"
export default {
  name: "CreateFactoringCompany",
  data() {
    return {
      valid: false,
      factoring: {
        businessName: null,
        addressLine1: null,
        city: null,
        state: null,
        postalCode: null,
        country: null,
        phone: null,
        email: null,
        contactName: null,
      },
      rules: validation.rules,
    }
  },
  computed: {
    ...mapGetters("create", ["factoringDialog"]),
    ...mapGetters("generic", ["countries"]),
  },
  methods: {
    closeDialog() {
      this.$store.commit("create/setFactoringDialog", false)
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    },
    submitFactoringCompany() {
      if (this.$refs.form.validate()) {
        const payload = {
          business_name: this.factoring.businessName,
          address_line_1: this.factoring.addressLine1,
          city: this.factoring.city,
          state: this.factoring.state,
          postal_code: this.factoring.postalCode,
          country: this.factoring.country,
          phone: this.factoring.phone,
          email: this.factoring.email,
          contact_name: this.factoring.contactName,
        }
        this.$store
          .dispatch("create/CREATE_FACTORING_COMPANY", payload)
          .then(() => {
            this.$refs.form.reset()
            this.$store.commit("create/setFactoringDialog", false)
          })
      }
    },
  },
}
</script>
