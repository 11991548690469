<template>
  <div class="wrapper">
    <div class="appBar">
      <div class="logo">
        <img src="@/assets/logo-mini.svg" />
      </div>

      <div class="buttons">
        <AddButton class="mr-8" />

        <!--<NotificationsButton class="mr-8" />-->

        <Avatar />
      </div>
    </div>

    <create-capacity />
    <create-location />
    <create-carrier />
    <create-load />
    <create-customer />
    <create-factoring-company />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import router from "@/router"
import Avatar from "@/views/layouts/default/AppBarComponents/Avatar"
import AddButton from "@/views/layouts/default/AppBarComponents/AddButton"
// import NotificationsButton from "@/views/layouts/default/AppBarComponents/NotificationsButton"
import CreateCapacity from "@/views/pages/create-components/CreateCapacity"
import CreateLocation from "@/views/pages/create-components/CreateLocation"
import CreateCarrier from "@/views/pages/create-components/CreateCarrier"
import CreateLoad from "@/views/pages/create-components/CreateLoad"
import CreateCustomer from "@/views/pages/create-components/CreateCustomer"
import CreateFactoringCompany from "@/views/pages/create-components/CreateFactoringCompany"
import common from "@/utils/common"

export default {
  name: "AppBar",
  components: {
    AddButton,
    Avatar,
    // NotificationsButton,
    CreateCapacity,
    CreateLocation,
    CreateCarrier,
    CreateLoad,
    CreateCustomer,
    CreateFactoringCompany,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      general: [
        { title: "Capacity" },
        { title: "Load" },
        { title: "Location", dataCy: "create-location-button" },
      ],
      account: [
        { title: "Customer" },
        { title: "Carrier" },
        { title: "Factoring" },
      ],
      admin: [{ title: "User" }, { title: "Group" }, { title: "Contract" }],
      profileDropdown: [{ title: "Carrier Sales Rep Dashboard" }],
    }
  },
  computed: {
    ...mapState(["drawer"]),
  },
  methods: {
    logout() {
      common.methods.clearAllLocalStorage()
      this.$cookie.delete("token")
      this.$cookie.delete("django_redirect_url")
      router.push("/pages/login")
    },
    ...mapMutations({
      setDrawer: "main/SET_DRAWER",
    }),

    capacityForm() {
      this.$store.commit("create/setCapacityDialog", true)
    },
    loadForm() {
      this.$store.commit("create/setLoadDialog", true)
    },
    locationForm() {
      this.$store.commit("create/setLocationDialog", true)
    },

    customerForm() {
      this.$store.commit("create/setCustomerDialog", true)
    },
    carrierForm() {
      this.$store.commit("create/setCarrierDialog", true)
    },
    factoringForm() {
      this.$store.commit("create/setFactoringDialog", true)
    },

    generalSelection(item) {
      switch (item.title) {
        case "Capacity":
          this.capacityForm()
          break
        case "Load":
          this.loadForm()
          break
        case "Location":
          this.locationForm()
      }
    },
    accountSelection(item) {
      switch (item.title) {
        case "Customer":
          this.customerForm()
          break
        case "Carrier":
          this.carrierForm()
          break
        case "Factoring":
          this.factoringForm()
      }
    },
  },
}
</script>

<style scoped>
.wrapper {
  z-index: 10;
}

.appBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  background-color: var(--dark-blue);
}

.logo {
  width: 24px;
  height: 24px;
}

.buttons {
  display: flex;
}
</style>
