<template>
  <v-dialog v-model="capacityDialog" persistent width="500">
    <v-card>
      <v-card-title style="background-color: #1e88e5">
        <v-row class="align-center">
          <v-col cols="12">
            <span class="text-h2 white--text">Create Capacity</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-select
                  v-model="capacity.matchType"
                  :items="types"
                  item-text="name"
                  item-value="id"
                  label="Type"
                  @change="changeMatchType"
                />
              </v-col>
              <v-col v-if="isQuote" cols="12" class="pb-0 pt-0">
                <v-radio-group
                  v-model="row"
                  row
                  mandatory
                  class="mt-1"
                  @change="changeCarrierSelection"
                >
                  <v-radio label="Existing Carrier" value="1" />
                  <v-radio label="New Carrier" value="2" />
                </v-radio-group>
              </v-col>
              <v-col cols="12" :md="isQuote ? 6 : 12" class="pb-0 pt-0">
                <v-autocomplete
                  v-if="isExistingCarrier"
                  v-model="capacity.carrier"
                  :items="carrierList"
                  :loading="carrierLoading"
                  :search-input.sync="carrierSearch"
                  color="info"
                  item-text="Name"
                  item-value="id"
                  label="Carrier"
                  class="mt-n2"
                  placeholder="Start typing to Search"
                  return-object
                  :rules="[(v) => !!v || 'Carrier is required']"
                  validate-on-blur
                  close-on-content-click
                  @change="selectedCarrier"
                  @keydown.enter="$event.stopPropagation()"
                >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5"> mdi-office-building </v-icon>
                  </template>
                  <v-list-item slot="append-item">
                    <v-btn text small @click="carrierForm()">
                      <v-icon>mdi-plus</v-icon> Add carrier
                    </v-btn>
                  </v-list-item>
                </v-autocomplete>
                <v-text-field
                  v-else
                  v-model="capacity.name"
                  label="Carrier Name"
                  color="info"
                  type="text"
                  class="mt-n2"
                  :rules="[rules.name, rules.required]"
                >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5"> mdi-office-building </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                v-if="isQuote"
                cols="12"
                :md="isQuote ? 6 : 12"
                class="pb-0 pt-0"
              >
                <v-autocomplete
                  v-model="selectedLoad"
                  :items="loadList"
                  :loading="loadLoading"
                  :search-input.sync="loadSearch"
                  color="info"
                  clearable
                  item-text="Name"
                  item-value="id"
                  label="Load"
                  placeholder="Start typing to Search"
                  return-object
                  required
                  :rules="[(v) => !!v || 'Load is required']"
                  validate-on-blur
                  close-on-content-click
                  class="mt-n2"
                  @change="loadSelected"
                  @keydown.enter="$event.stopPropagation()"
                >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5">
                      mdi-package-variant-closed
                    </v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col v-if="!isQuote" cols="12" sm="6" md="6">
                <div
                  class="
                    v-input
                    pt-0
                    theme--light
                    v-text-field v-text-field--is-booted
                  "
                >
                  <v-icon
                    v-if="$vuetify.breakpoint.lgAndUp"
                    color="green"
                    class="pr-2"
                  >
                    mdi-map-marker
                  </v-icon>
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        <gmap-autocomplete
                          ref="autocompleteOrigin"
                          placeholder="Source Location"
                          :options="{
                            fields: ['address_components', 'geometry'],
                          }"
                          @place_changed="setOriginPlace"
                        >
                          <template v-slot:input="slotProps">
                            <v-text-field
                              ref="input"
                              label="Origin"
                              placeholder="Origin"
                              prepend-icon="mdi-map-marker"
                              @listeners="slotProps.listeners"
                              @attrs="slotProps.attrs"
                            />
                          </template>
                        </gmap-autocomplete>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col v-if="!isQuote" cols="12" sm="6" md="6">
                <div
                  class="
                    v-input
                    pt-0
                    theme--light
                    v-text-field v-text-field--is-booted
                  "
                >
                  <v-icon
                    v-if="$vuetify.breakpoint.lgAndUp"
                    color="red"
                    class="pr-2"
                  >
                    mdi-map-marker
                  </v-icon>
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        <gmap-autocomplete
                          ref="autocompleteDestination"
                          placeholder="Destination Location"
                          :options="{
                            fields: ['address_components', 'geometry'],
                          }"
                          @place_changed="setDestinationPlace"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col v-if="!isQuote" cols="12" sm="6" md="6">
                <v-menu
                  ref="menu"
                  v-model="sourceDateMenu"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="capacity.sourceDate"
                      label="Available Date *"
                      readonly
                      color="info"
                      :rules="[(v) => !!v || 'Available date is required']"
                      v-on="on"
                    >
                      <template v-slot:prepend>
                        <v-icon color="#1E88E5"> mdi-calendar </v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="capacity.sourceDate"
                    no-title
                    scrollable
                  >
                    <v-spacer />
                    <v-btn text color="info" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="info" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col v-if="!isQuote" cols="12" sm="6" md="6">
                <v-autocomplete
                  v-if="!isQuote"
                  v-model="capacity.equipmentType"
                  :items="equipmentTypes"
                  label="Equipment Type *"
                  item-text="name"
                  item-value="id"
                  required
                  color="info"
                  :rules="[(v) => !!v || 'Equipment is required']"
                  @keydown.enter="$event.stopPropagation()"
                >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5"> mdi-truck-trailer </v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col v-if="!isQuote" cols="12" sm="4" class="pb-0 pt-0">
                <v-text-field
                  v-model="capacity.qtyTrucks"
                  label="Qty Trucks *"
                  required
                  color="info"
                  validate-on-blur
                  :rules="[(v) => !!v || 'Needs to be at least one truck']"
                >
                  <template v-slot:prepend>
                    <v-icon v-if="!isQuote" color="#1E88E5">
                      mdi-truck-check-outline
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col v-if="!isQuote" cols="12" sm="4" class="pb-0 pt-0">
                <v-text-field
                  v-if="!isQuote"
                  v-model="capacity.qtyLoads"
                  label="Qty Loads"
                  color="info"
                >
                  <template v-slot:prepend>
                    <v-icon v-if="!isQuote" color="#1E88E5"> mdi-dolly </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col v-if="isQuote" cols="12" md="6" class="pb-0 pt-0">
                <v-text-field
                  v-model="capacity.email"
                  label="Email"
                  color="info"
                  :rules="[rules.email]"
                  :disabled="isExistingCarrier"
                  validate-on-blur
                >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5"> mdi-email </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col v-if="isQuote" cols="12" md="6" class="pb-0 pt-0">
                <v-text-field
                  v-model="capacity.phone"
                  label="Phone"
                  color="info"
                  :rules="[rules.phone]"
                  :disabled="isExistingCarrier"
                  validate-on-blur
                >
                  >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5"> mdi-phone </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col v-if="isQuote" cols="12" md="6" class="pb-0 pt-0">
                <v-text-field
                  v-model="capacity.dotNumber"
                  label="DOT Number"
                  color="info"
                  :rules="
                    !capacity.mcNumber
                      ? [rules.intDigit, rules.required]
                      : [rules.intDigit]
                  "
                  :disabled="isExistingCarrier"
                  validate-on-blur
                >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5"> mdi-barcode </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col v-if="isQuote" cols="12" md="6" class="pb-0 pt-0">
                <v-text-field
                  v-model="capacity.mcNumber"
                  label="MC Number"
                  color="info"
                  :rules="
                    !capacity.dotNumber
                      ? [rules.intDigit, rules.required]
                      : [rules.intDigit]
                  "
                  :disabled="isExistingCarrier"
                  validate-on-blur
                >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5"> mdi-barcode </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col class="sols pb-0 pt-0" :sm="isQuote ? 6 : 4">
                <v-text-field
                  v-model="capacity.rate"
                  label="Rate"
                  color="info"
                  type="number"
                  :rules="[rules.amount, rules.required]"
                  validate-on-blur
                >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5"> mdi-currency-usd </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                v-if="isQuote"
                class="sols pb-0 pt-0"
                :sm="isQuote ? 6 : 4"
              >
                <v-text-field
                  v-model="capacity.dispatchName"
                  label="Dispatch Name"
                  color="info"
                  :rules="[rules.alphaNumeric]"
                  validate-on-blur
                >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5"> mdi-contacts </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="red" text @click="closeDialog()"> Close </v-btn>
        <v-btn color="info" text @click="submitCapacity()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import debounce from "lodash/debounce"
import api from "@/api/api"
import { mapGetters } from "vuex"
import validation from "@/utils/form-validation"
import common from "@/utils/common"

const snackbarType = common.data.snackbarType

export default {
  name: "CreateCapacityForm",
  data: () => {
    return {
      originLocation: null,
      row: "1",
      capacity: {
        carrier: null,
        sourceDate: null,
        equipmentType: null,
        qtyTrucks: null,
        qtyLoads: null,
        source: 1,
        matchType: 5,
        carrierBid: null,
        sourceLocation: {
          latitude: null,
          longitude: null,
          currentPlace: null,
        },
        destinationLocation: {
          latitude: null,
          longitude: null,
          currentPlace: null,
        },
        quoteId: null,
        name: null,
        email: null,
        phone: null,
        dotNumber: null,
        mcNumber: null,
        dispatchName: null,
      },
      valid: false,
      sourceDateMenu: false,
      carrierSearch: null,
      carriers: [],
      carrierLoading: false,
      loadSearch: null,
      loads: [],
      loadLoading: false,
      types: [
        {
          id: 5,
          name: "User Entered",
        },
        {
          id: 4,
          name: "Quote",
        },
      ],
      isQuote: false,
      isExistingCarrier: true,
      rules: validation.rules,
      selectedLoad: null,
    }
  },
  computed: {
    ...mapGetters("create", ["capacityDialog"]),
    ...mapGetters("carrier", ["currentCarrier"]),
    ...mapGetters("generic", ["equipmentTypes"]),
    ...mapGetters("loadDetail", ["loadList"]),
    loadList() {
      return this.loads.map((load) => {
        const Name = load.text
        return Object.assign({}, load, { Name })
      })
    },
    carrierList() {
      return this.carriers.map((carrier) => {
        const Name = carrier.text
        return Object.assign({}, carrier, { Name })
      })
    },
  },
  watch: {
    loadSearch(val) {
      if (this.loadSearch === "") {
        this.loads = []
        return
      }
      if (this.loads.length > 0) return

      if (this.loadLoading) return
      this.loadLoading = true
      this.getLoadSearch()
    },
    carrierSearch(val) {
      if (this.carrierSearch === "") {
        this.carriers = []
        return
      }
      if (this.carriers.length > 0) return

      if (this.carrierLoading) return
      this.carrierLoading = true
      this.getCarrierSearch()
    },
    currentCarrier() {
      this.capacity.email = this.currentCarrier.email
      this.capacity.phone = this.currentCarrier.phone
      this.capacity.dotNumber = this.currentCarrier.dotNumber
      this.capacity.mcNumber = this.currentCarrier.mcNumber
    },
  },
  methods: {
    setOriginPlace(place) {
      this.capacity.sourceLocation.latitude = parseFloat(
        place.geometry.location.lat()
      )
      this.capacity.sourceLocation.longitude = parseFloat(
        place.geometry.location.lng()
      )
      this.capacity.sourceLocation.currentPlace = place
    },
    setDestinationPlace(place) {
      this.capacity.destinationLocation.latitude = parseFloat(
        place.geometry.location.lat()
      )
      this.capacity.destinationLocation.longitude = parseFloat(
        place.geometry.location.lng()
      )
      this.capacity.destinationLocation.currentPlace = place
    },
    carrierForm() {
      this.$store.commit("create/setCarrierDialog", true)
    },
    closeDialog() {
      this.$store.commit("create/setCapacityDialog", false)
      this.$refs.form.reset()
    },
    submitCapacity() {
      if (this.isQuote === false) {
        if (this.$refs.form.validate()) {
          this.$store
            .dispatch("create/CREATE_CAPACITY", this.capacity)
            .then(() => {
              this.$refs.form.reset()
            })
        }
      } else {
        if (this.$refs.form.validate()) {
          const payload = {
            loadId: this.selectedLoad.id,
            carrierName:
              this.isExistingCarrier === true
                ? this.capacity.carrier.text.split(" DOT")[0]
                : this.capacity.name,
            carrierBid: this.capacity.rate,
            email: this.capacity.email,
            carrierPhone: this.capacity.phone,
            dotNumber: this.capacity.dotNumber,
            mcNumber: this.capacity.mcNumber,
            source: 2, // TMS Created
            dispatchName: this.capacity.dispatchName,
          }
          this.$store.dispatch("create/CREATE_QUOTE", payload).then((data) => {
            this.$refs.form.reset()
            this.$store.commit("create/setCapacityDialog", false)
          })
        }
      }
    },
    getLoadSearch: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/loads/load-front-end-autocomplete/`,
          {
            params: {
              q: this.loadSearch,
            },
          }
        )
        .then((res) => {
          this.loads = res.data.results
          this.loadLoading = false
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Whoops, something went wrong",
            error: err,
            type: snackbarType.error,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
        })
        .finally((this.loadLoading = false))
    }, 500),
    getCarrierSearch: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/accounts/carrier-front-end-autocomplete/`,
          {
            params: {
              q: this.carrierSearch,
            },
          }
        )
        .then((res) => {
          this.carriers = res.data.results
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Whoops, something went wrong",
            error: err,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
        })
        .finally((this.carrierLoading = false))
    }, 500),
    changeMatchType(value) {
      this.$refs.form.resetValidation()
      if (value === 4) {
        this.isQuote = true
      } else {
        this.isQuote = false
        this.selectedLoad = null
      }
    },
    changeCarrierSelection(value) {
      this.$refs.form.resetValidation()
      if (value === "1") {
        this.isExistingCarrier = true
      } else {
        this.isExistingCarrier = false
        this.capacity.carrier = null
        this.capacity.name = ""
        this.capacity.email = ""
        this.capacity.phone = ""
        this.capacity.dotNumber = ""
        this.capacity.mcNumber = ""
      }
    },
    selectedCarrier(value) {
      if (this.isQuote) {
        this.$store.dispatch("carrier/GET_CARRIER_COMPANY", value.id)
      }
    },
    loadSelected(value) {
      this.selectedLoad = value.load.id
    },
  },
}
</script>
