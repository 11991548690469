<template>
  <v-dialog v-model="locationDialog" persistent width="500">
    <v-card data-cy="create-location-dialog">
      <v-card-title style="background-color: #1e88e5">
        <v-row class="align-center">
          <v-col cols="12">
            <span class="text-h2 white--text">Create Location</span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" class="pt-0 pb-2">
                <v-text-field
                  v-model="location.name"
                  label="Location Name"
                  color="info"
                  data-cy="location-name"
                  :rules="[rules.required, rules.alphaNumeric]"
                >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5" class="mt-2"
                      >mdi-office-building</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="d-flex py-0">
                <v-icon color="#1E88E5" class="mr-2">mdi-domain</v-icon>
                <customer-auto-complete class="flex-grow-1" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="location.contactName"
                  label="Contact Name"
                  color="info"
                  :rules="[rules.alphaNumeric]"
                >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5">mdi-contacts</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="location.contactPhone"
                  label="Phone"
                  color="info"
                  :rules="[rules.phone]"
                >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5">mdi-phone</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="location.hours"
                  label="Hours"
                  color="info"
                >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5">mdi-clock</v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="d-flex align-start py-0">
                <v-icon color="#1E88E5" class="mt-6 mr-2">mdi-city</v-icon>
                <detail-location-auto-complete
                  class="flex-grow-1"
                  @event="setPlace"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="py-0">
                <v-textarea
                  v-model="location.notes"
                  label="Notes"
                  counter
                  maxlength="300"
                  color="info"
                >
                  <template v-slot:prepend>
                    <v-icon color="#1E88E5">mdi-note</v-icon>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="red" text @click="closeDialog()"> Close </v-btn>
        <v-btn
          color="info"
          text
          data-cy="location-submit-button"
          @click="submitLocation()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import CustomerAutoComplete from "@/views/pages/components/CustomerAutoComplete"
import DetailLocationAutoComplete from "@/views/pages/components/DetailLocationAutoComplete"
import validation from "@/utils/form-validation"

export default {
  name: "CreateLocation",
  components: {
    CustomerAutoComplete,
    DetailLocationAutoComplete,
  },
  data() {
    return {
      location: {
        name: null,
        notes: null,
        address: null,
        currentPlace: null,
        latitude: null,
        longitude: null,
        contactName: null,
        contactPhone: null,
        hours: null,
        customerCompany: null,
      },
      rules: validation.rules,
      valid: false,
    }
  },
  computed: {
    ...mapGetters("create", ["locationDialog"]),
    ...mapGetters("generic", ["customerId"]),
  },
  methods: {
    setPlace(place) {
      this.location.latitude = parseFloat(place.position.lat)
      this.location.longitude = parseFloat(place.position.lng)
      this.location.currentPlace = place
    },
    validate() {
      this.$refs.form.validate()
    },
    submitLocation() {
      if (this.$refs.form.validate()) {
        this.location.customerCompany = this.customerId
        this.$store
          .dispatch("create/CREATE_LOCATION", this.location)
          .then((data) => {
            this.$store.commit("generic/setCustomerId", null)
            this.$refs.form.reset()
          })
      }
    },
    closeDialog() {
      this.$store.commit("create/setLocationDialog", false)
      this.$refs.form.reset()
    },
  },
}
</script>
