<template>
  <div id="create-load">
    <v-dialog v-model="loadDialog" persistent width="500">
      <v-card>
        <v-card-title style="background-color: #1e88e5" class="pt-0 pl-0">
          <v-row class="align-center">
            <v-col cols="12" sm="12" md="8" lg="8" xl="8">
              <span class="text-h2 white--text">Create Load</span>
            </v-col>
            <v-col class="py-0">
              <v-img
                v-if="$vuetify.breakpoint.mdAndUp"
                src="@/assets/create-load-box.svg"
                height="120"
                width="120"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="load-form" v-model="valid">
            <v-container>
              <v-row>
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1">
                  <v-icon left color="#1E88E5"> mdi-account-circle </v-icon>
                </v-col>
                <v-col cols="11">
                  <customer-auto-complete />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="load.mode"
                    :items="modes"
                    label="Mode"
                    item-text="name"
                    item-value="id"
                    dense
                    solo
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="load.orderType"
                    :items="orderTypes"
                    label="Order Type"
                    item-text="name"
                    item-value="id"
                    dense
                    solo
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="load.equipment"
                    :items="equipmentTypes"
                    label="Equipment"
                    item-text="name"
                    item-value="id"
                    dense
                    solo
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="pa-0">
                  <location-auto-complete form-type="1" direction="source" />
                  <location-auto-complete
                    form-type="1"
                    direction="destination"
                  />
                </v-col>
                <v-col
                  v-if="$vuetify.breakpoint.mdAndUp"
                  cols="1"
                  class="d-flex flex-column mb-4 mt-1"
                >
                  <v-icon center small color="green"> mdi-calendar </v-icon>
                  <v-icon center small color="red"> mdi-calendar </v-icon>
                </v-col>
                <v-col cols="11" md="5" class="pa-0">
                  <v-menu
                    v-model="pickupCalendar"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="load.pickupDate"
                        label="Pickup Date"
                        :rules="[rules.required]"
                        persistent-hint
                        readonly
                        class="pt-0"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="load.pickupDate"
                      no-title
                      @input="pickupCalendar = false"
                    />
                  </v-menu>
                  <v-menu
                    v-model="destinationCalendar"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="load.destinationDate"
                        label="Delivery Date"
                        :rules="[rules.required]"
                        persistent-hint
                        readonly
                        class="pt-0"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="load.destinationDate"
                      no-title
                      @input="destinationCalendar = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1">
                      <v-icon left color="#1E88E5"> mdi-cube-send </v-icon>
                    </v-col>
                    <v-col cols="10" class="pt-0 pb-0">
                      <v-textarea
                        v-model="load.commodity"
                        class="mx-2 pt-0"
                        label="Commodity"
                        rows="3"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1">
                      <v-icon left color="#1E88E5"> mdi-currency-usd </v-icon>
                    </v-col>
                    <v-col cols="10" class="pt-0 pb-0">
                      <v-text-field
                        v-model="load.customerPrice"
                        label="Customer Price"
                        color="info"
                        class="pt-0"
                        :rules="[rules.amount]"
                      />
                    </v-col>
                    <v-col cols="1"> &nbsp; </v-col>
                    <v-col cols="10" class="pt-0 pb-0">
                      <v-text-field
                        v-model="load.currierMin"
                        label="Currier Min"
                        color="info"
                        class="pt-0"
                        :rules="[rules.amount]"
                      />
                    </v-col>
                    <v-col cols="1"> &nbsp; </v-col>
                    <v-col cols="10" class="pt-0 pb-0">
                      <v-text-field
                        v-model="load.currierMax"
                        label="Currier Max"
                        color="info"
                        class="pt-0"
                        :rules="[rules.amount]"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <div
                    class="rounded"
                    style="background-color: #f6f6f6; height: 256px"
                  >
                    <v-list-item-subtitle
                      class="text--lighten-2 pl-2 box-header"
                    >
                      Comments
                    </v-list-item-subtitle>
                    <v-textarea
                      v-model="load.comments"
                      class="mx-1"
                      rows="9"
                      style="background-color: #fff; resize: none"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer />
          <v-btn color="red" text @click="closeDialog()"> Close </v-btn>
          <v-btn color="info" text @click="submitLoad()"> Create </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import LocationAutoComplete from "@/views/pages/components/LocationAutoComplete"
import CustomerAutoComplete from "@/views/pages/components/CustomerAutoComplete"
import validation from "@/utils/form-validation"
import common from "@/utils/common"
export default {
  name: "CreateLoad",
  components: {
    LocationAutoComplete,
    CustomerAutoComplete,
  },
  data() {
    return {
      valid: false,
      pickupCalendar: false,
      destinationCalendar: false,
      load: {
        customer: 0,
        mode: 0,
        orderType: 0,
        equipment: 0,
        pickupLocation: null,
        pickupDate: null,
        destinationLocation: null,
        destinationDate: null,
        commodity: null,
        customerPrice: null,
        comments: null,
        currierMin: null,
        currierMax: null,
      },
      rules: validation.rules,
    }
  },
  computed: {
    ...mapGetters("create", ["loadDialog"]),
    ...mapGetters("generic", [
      "equipmentTypes",
      "modes",
      "orderTypes",
      "customerId",
    ]),
    ...mapGetters("loadDetail", ["shipperId", "consigneeId", "loadId"]),
  },
  watch: {
    shipperId() {
      this.load.pickupLocation = this.shipperId
    },
    consigneeId() {
      this.load.destinationLocation = this.consigneeId
    },
    customerId() {
      this.load.customer = this.customerId
    },
  },
  methods: {
    submitLoad() {
      if (this.load.customer === null || this.load.customer === 0) {
        const snackbar = {
          status: true,
          text: "Please select customer",
          type: common.data.snackbarType.error,
        }
        this.$store.commit("main/SET_SNACKBAR", snackbar)
      } else if (this.load.shipper === null || this.load.shipper === 0) {
        const snackbar = {
          status: true,
          text: "Please select pickup location",
          type: common.data.snackbarType.error,
        }
        this.$store.commit("main/SET_SNACKBAR", snackbar)
      } else if (this.load.consignee === null || this.load.consignee === 0) {
        const snackbar = {
          status: true,
          text: "Please select destination location",
          type: common.data.snackbarType.error,
        }
        this.$store.commit("main/SET_SNACKBAR", snackbar)
      } else {
        if (this.$refs["load-form"].validate()) {
          const payload = {
            customer_company: this.load.customer,
            equipment_type: this.load.equipment,
            mode: this.load.mode,
            shipper: this.load.pickupLocation,
            consignee: this.load.destinationLocation,
            pickup_date: this.load.pickupDate,
            delivery_date: this.load.destinationDate,
            commodity: this.load.commodity,
            description: this.load.comments,
            customer_price: this.load.customerPrice,
            carrier_start_buy: this.load.currierMin,
            carrier_max_buy: this.load.currierMax,
            order_type: this.load.orderType,
          }
          this.$store.dispatch("loadDetail/CREATE_NEW_LOAD", payload).then(
            () => {
              this.closeDialog()
              const routeData = this.$router.resolve({
                name: "Load Detail",
                params: { id: this.loadId },
              })
              window.open(routeData.href, "_blank")
            },
            () => {}
          )
        }
      }
    },
    closeDialog() {
      this.$store.commit("create/setLoadDialog", false)
      this.$refs["load-form"].reset()
    },
  },
}
</script>
<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}
.v-textarea /deep/ .v-text-field__slot textarea {
  resize: none;
}
</style>
