<template>
  <div
    class="wrapper"
    @mouseover="isShown = true"
    @mouseleave="isShown = false"
  >
    <div @click="isShown = true">
      <slot name="trigger"></slot>
    </div>

    <transition name="fade">
      <div v-if="isShown" class="contentWrapper">
        <div class="contentBackground">
          <div class="tip" :style="{ backgroundColor: tipColor }" />
          <div class="content">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import colors from "@/assets/colors.json"

export default {
  name: "Tooltip",
  props: {
    tipColor: {
      type: String,
      default: colors["--white"],
    },
  },
  data() {
    return {
      isShown: false,
    }
  },
}
</script>

<style scoped>
@import "../../assets/colors.css";

.wrapper {
  position: relative;
}

.contentWrapper {
  position: absolute;
  padding: 24px;
  right: -34px;
  top: 100%;
}

.contentBackground {
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 0px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.content {
  position: relative;
  min-width: 100px;
  background-color: var(--white);
  border-radius: 8px;
}

.tip {
  position: absolute;
  width: 28px;
  height: 28px;
  top: 10px;
  right: 43px;
  content: "";
  border-radius: 0px 8px 0px 0px;
  transform: rotate(-45deg);
}

/* fade animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/* / fade animation */
</style>
